body,
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Helvetica';
  background-color: #1C1D20;
}

a {
  text-decoration: none;
}

.filter-for-bg {
  background: linear-gradient(180deg, #1C1D20, #1c1d2000 50%, #1C1D20);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
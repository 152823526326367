.footer-area {
    padding: 30px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1C1D20;
    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        .version {
            display: flex;
            flex-direction: column;
            small {
                color: lightseagreen;
                margin-bottom: 20px;
                font-size: 14px;
            }

            p {
                color: white;
                font-size: 14px;
            }
        }
        .local-time {
            display: flex;
            flex-direction: column;
            small {
                color: lightseagreen;
                margin-bottom: 20px;
                font-size: 14px;
            }

            p {
                color: white;
                font-size: 14px;
            }
        }
    }
    .right {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        .socials {
            display: flex;
            flex-direction: column;
            small {
                color: lightseagreen;
                margin-bottom: 20px;
                font-size: 14px;
            }
            .links {
                display: flex;
                gap: 20px;
                .link {
                    color: white;
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .footer-area{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 0;
        .left{
            flex-direction: column;
        }
        .right {
            margin-bottom: 40px;

            .socials {
                margin-top: 40px;
                .links {
                    flex-direction: column
                }
                
            }
        }
    }
}
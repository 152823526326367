.top-links-area {
    position: absolute;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    z-index: 99;
    .left {
        margin-left: 70px;
        .link{
            color: white;
            font-size: 17px;
            font-weight: bold;
        }
    }
    .right {
        margin-right: 70px;
        display: flex;
        gap: 30px;
        .link {
            color: white;
            font-size: 17px;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 800px) {
    .top-links-area {
        .left {
            margin-left: 30px;
        }
        .right {
            margin-right: 30px;
            gap: 10px;
        }
    }
}

.project{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 50px 100px 50px 100px;
    border-top: 1px solid rgb(201, 201, 201);
    cursor: pointer;
    transition: all 0.2s;

    h2{
        font-size: 60px;
        margin: 0px;
        font-weight: 400;
        transition: all 0.4s;
    }

    p{
        transition: all 0.4s;
        font-weight: 300;
    }

    &:last-of-type{
        border-bottom: 1px solid rgb(201, 201, 201);
    }

    &:hover{
        opacity: 0.5;

        h2{
            transform: translateX(-10px);
        }

        p{
            transform: translateX(10px);
        }
    }
}

@media screen and (max-width: 1000px) {
    .project {
        width: auto;

        h2,
        p {
            font-size: 20px;
        }

        p {
            display: none;
        }
        

        
    }
    
}

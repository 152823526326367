.form-area {
    margin-top: 180px;
    display: flex;
    align-items: start;
    justify-content: center;
    margin-bottom: 100px;
    gap: 150px;
    form {
        width: 600px;
        .title-area {
            width: 500px;
            h1 {
                font-size: 60px;
                color: white;
                margin-bottom: 60px;
            }
        }
        .input-area {
            display: flex;
            flex-direction: column;
            border-top: 3px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 3px solid rgba(255, 255, 255, 0.1);
            padding: 20px 0;

            small {
                font-size: 16px;
                margin-right: 20px;
                color: rgb(110, 109, 109);
            }

            label {
                font-size: 30px;
                color: white;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                justify-content: start;
            }
            input,
            textarea {
                border: none;
                background: transparent;
                font-size: 25px;
                color: white;
                margin-left: 40px;
                
            }

            textarea {
                min-width: 100%;
                max-width: 100%;
                min-height: 100px;
            }

            input:focus,
            textarea:focus {
                outline: none;
            }
              
        }
        .button-area {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: start;
            justify-content: end;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgb(122, 122, 252);
                font-size: 16px;
                color: white;
                width: 140px;
                height: 140px;
                border-radius: 50%;
                border: none;
                cursor: pointer;
            }
            button:hover {
                font-weight: bold;
                background-color: rgb(75, 75, 219);
            }
           
        }
    }
    .contact-details {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: start;
        justify-content: start;
        .photo-area {
            display: flex;
            flex-direction: column;
            img {
                width: 100px;
                border-radius: 50%;
                margin-bottom: 40px;
            }
            .icon {
                color: white;
                margin-bottom: 40px;
                font-size: 30px;
            }
        }
        .contacts-area {
            display: flex;
            flex-direction: column;
            gap: 5px;
            h1 {
                color: rgb(177, 177, 177);
                font-size: 20px;
            }
            p {
                color: white;
                font-size: 20px;
            }
        }
        .socials-area {
            display: flex;
            flex-direction: column;
            gap: 5px;
            h1 {
                color: rgb(177, 177, 177);
                font-size: 20px;
            }
            a {
                color: white;
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .form-area {
        flex-direction: column;
        gap: 70px;

        form {
            width: 300px;
            .title-area {

                h1 {
                    font-size: 20px;
                    margin-bottom: 25px;
                }
                
            }

            .input-area {
                label {
                    font-size: 20px;
                }
                textarea {
                    margin-left: 0px;
                    min-height: 140px;
                }
            }

            .button-area {
                align-items: end;
                justify-content: start;
            }
        }

        
    }
}
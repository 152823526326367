.about-area {
    background: #1C1D20;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    .header-title-area {
        margin-top: 150px;
        width: 40%;
        text-align: center;
        // background: red;
        padding: 20px 0;
        color: white;
        font-size: 30px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }
    .ceo-profile-area {
        margin-top: 100px;
        display: flex;
        align-items: start;
        justify-content: center;
        gap: 50px;

        .text-area {
            width: 20%;
            display: flex;
            flex-direction: column;
            p{
                color: white;
                font-size: 20px;
            }
            small {
                margin-top: 20px;
                color: rgb(166, 166, 166);
                font-size: 20px;
            }
        }

        .image-area {
            height: 600px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .about-area {
        .header-title-area {
            width: 90%;
            h1 {
                font-size: 30px;
            }
        }
        .ceo-profile-area {margin-top: 20px;
            width: 100%;
            flex-direction: column;
            align-items: center;
            .text-area {
                width: 90%;
            }
            .image-area{
                height: auto;
            }
        }
        
    }
   
}
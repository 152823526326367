.projects{
    display: flex;
    align-items: center;
    padding-left: 200px;
    padding-right: 200px;
    flex-direction: column;
    // margin-top: 300px;
    background: #1C1D20;
    padding-bottom: 100px;
   

    .body{
        max-width: 1400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;
    }
    
    .modalContainer{
        height: 350px;
        width: 400px;
        position: fixed;
        top: 50%;
        left: 50%;
        background-color: white;
        pointer-events: none;
        overflow: hidden;
        z-index: 3;
    }
    
    .modalSlider{
        height: 100%;
        width: 100%;
        position: relative;
        transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    }
    
    .modal{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .modal img{
        height: auto;
    }
    
    .cursor, .cursorLabel{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #455CE9;
        color: white;
        position: fixed;
        z-index:3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 300;
        pointer-events: none;
    }
    
    .cursorLabel{
        background-color: transparent;
    }
    
    
}

@media screen and (max-width: 1000px) {
    .projects {
        padding-left: 0px;
        padding-right: 0px;
        // margin-top: 100px;

        h2 {
            font-size: 10px;
        }

        
    }
    
}


.work-area {
    background: #1C1D20;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    .title-area {
        margin-top: 150px;
        width: 50%;
        text-align: center;
        padding: 20px 0;
        color: white;
        font-size: 30px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }
    .filter-area {
        display: flex;
        padding: 50px;
        gap: 20px;
    }
    .images-area {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        .image {
            width: 300px;
            cursor: pointer;
            overflow: hidden;
            position: relative;
            border-radius: 5px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                transition: all ease .4s;
            }
        }

        .image:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
    .dots-container {
        display: flex;
        gap: 10px;
        margin-top: 10px;

        .dot {
            width: 10px;
            height: 10px;
            background-color: rgb(185, 185, 185);
            border-radius: 50%;
            opacity: 0;
        }

        &.animate-dots {
            .dot:nth-child(1) {
                animation: fadeInOut 1.5s infinite;
                animation-delay: 0s;
            }
            .dot:nth-child(2) {
                animation: fadeInOut 1.5s infinite;
                animation-delay: 0.5s;
            }
            .dot:nth-child(3) {
                animation: fadeInOut 1.5s infinite;
                animation-delay: 1s;
            }
            .dot:nth-child(4) {
                animation: fadeInOut 1.5s infinite;
                animation-delay: 1.5s;
            }
        }
    }
    @keyframes fadeInOut {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
}

@media screen and (max-width: 900px) {
    .work-area {
        .title-area {
            width: 90%;
            font-size: 15px;
        }
        .filter-area {
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 1000px) {
    .work-area {
        .images-area {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (max-width: 700px) {
    .work-area {
        .images-area {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
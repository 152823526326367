.slidingImages{
    display: flex;
    flex-direction: column;
    gap: 3vw;
    position: relative;
    // margin-top: 200px;
    background-color: white;
    padding: 90px 0;
    z-index: 1;
    
    .slider{
        display: flex;
        position: relative;
        gap: 10vw;
        width: 120vw;
        left: -10vw;
        .project{
            width: 25%;
            height: 20vw;
            display: flex;
            align-items: center;
            justify-content: center;
            .imageContainer{
                position: relative;
                width: 80%;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    object-fit: cover;
                }
            }
        }
    }
    .circleContainer{
        background-color: red;
        position: relative;
        margin-top: 100px;
        .circle{
            height: 1550%;
            width: 120%;
            left: -10%;
            border-radius: 0 0 50% 50%;
            background-color: white;
            z-index: 1;
            position: absolute;
            box-shadow: 0px 60px 50px rgba(0, 0, 0, 0.748);
        }
    }
}

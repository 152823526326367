.image-open-area {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .image-area {

        height: 700px;

        button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background: transparent;
            margin-bottom: 20px;
    
            .icon {
                color: white;
                font-size: 30px;
            }
    
        }
        img {
            max-width: 400px;
            border-radius: 5px;
            margin-bottom: 10px;object-fit: contain;
        }
        .info-area {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                color: white;
            }
        }
        
    }

   
}

@media screen and (max-width: 650px) {
    .image-open-area {
        .image-area {
            height: auto;
            width: 90%;
            img {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
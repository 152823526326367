.dots-container {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    margin-left: 20px;

    .dot {
        width: 5px;
        height: 5px;
        background-color: rgb(185, 185, 185);
        border-radius: 50%;
        opacity: 0;
    }

    &.animate-dots {
        .dot:nth-child(1) {
            animation: fadeInOut 1.5s infinite;
            animation-delay: 0s;
        }
        .dot:nth-child(2) {
            animation: fadeInOut 1.5s infinite;
            animation-delay: 0.5s;
        }
        .dot:nth-child(3) {
            animation: fadeInOut 1.5s infinite;
            animation-delay: 1s;
        }
        .dot:nth-child(4) {
            animation: fadeInOut 1.5s infinite;
            animation-delay: 1.5s;
        }
    }
}
@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}


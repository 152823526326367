.services-area {
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #1C1D20;

    .top-title-area {
        // background: red;
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: center;

        h1 {
            color: white;
            font-size: 60px;
        }
    }
    .card-area {
        display: flex;
        align-items: start;
        justify-content: center;
        gap: 50px;
        margin: 100px 0px;

        .card {
            width: 400px;

            .number-area {
                width: 100%;
                border-bottom: 2px solid rgba(255, 255, 255, 0.1);
                p {
                    color: rgb(136, 135, 135);
                    margin-bottom: 10px;
                }
            }
            .title-area {
                width: 100%;
                color: white;
                margin: 30px 0;
                font-size: 20px;
            }
            .text-area {
                width: 100%;
                p {
                    color: white;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .services-area {
        width: 100%;
        align-items: center;
        .top-title-area {
            width: 90%;
            h1 {
                font-size: 30px;
            }
        }
        .card-area {
            flex-direction: column;
            width: 100%;
            .card {
                width: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: column;

                .number-area,
                .title-area,
                .text-area {
                    width: 80%;
                }
            }
        }
        
    }   
}

@media screen and (max-width: 900px) {
    .services-area {
        
        .top-title-area {
            flex-direction: column;
            align-items: center;
            gap: 20px;
            
        }
    }
}
.header-area {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    // background: #1C1D20;
   
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 2;
    }
}